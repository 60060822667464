import React, {useEffect} from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
// Components
import Layout from "../components/layout"
import BreadCrumbs from "components/BreadCrumbs/StaticBreadcrumb"
import {CustomLinks} from "../components/common/menuUtils"
import {isLoggedIn} from "../services/auth"

const breadCrumbsData = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Thank You',
    },
];
const Thankyou = () => {
    const location = useLocation();
    const state = location.state;
    const status = state?.status;
    useEffect(() => {
        if (!status) {
            if(isLoggedIn()) {
                navigate(`/${CustomLinks.dashboard}/`);
            }else {                
                navigate(`/${CustomLinks.login}/`);
            }
        }
    }, [status]); 

    return (
        <Layout variant={""} >
             <BreadCrumbs
                breadCrumbsData={ breadCrumbsData }
            />
            <div className="dashboard-details-wrapper thankyou-wrapper">          
                <Container>
                    <Row className="dashboard-details-header">
                        <Col lg="12">
                            <h1 className="general-heading">Hi <sapn className="user_name">{state?.nego_name}</sapn>,</h1>
                            <p>Thank you for registering with us as an agent. We will promptly review your information and send a confirmation message to the email address you provided.</p>
                        </Col>
                    </Row>

                </Container>
            </div>    
        </Layout>
    )
}

export default Thankyou